import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { MenuConfigService } from "../menu-config.service";
import { ClassInitService } from "../class-init.service";
import * as objectPath from "object-path";
import { LayoutConfigService } from "../layout-config.service";
import { TokenStorage } from "../../auth/token-storage.service";

@Injectable()
export class MenuAsideService {
  classes: string;
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  isDropdown: number = 0;
  dropdownTimeout: number;
  isScrollable: number = 0;

  constructor(
    private menuConfigService: MenuConfigService,
    private classInitService: ClassInitService,
    private layoutConfigService: LayoutConfigService,
    private tokenStorage: TokenStorage
  ) {
    var isAdmin = JSON.parse(localStorage.getItem("userRoles"));
    // get menu list
    this.menuConfigService.onMenuUpdated$.subscribe((model) => {
      setTimeout(() =>
        this.menuList$.next(
          isAdmin[0] != "ADMIN"
            ? this.MakeRestrictedAsideItems(
                objectPath.get(model.config, "aside.items")
              )
            : objectPath.get(model.config, "aside.items")
        )
      );
    });

    this.layoutConfigService.onLayoutConfigUpdated$.subscribe((config) => {
      if (objectPath.get(config, "config.aside.left.fixed")) {
        this.isScrollable = 1;
        this.isDropdown = 0;
      }

      // tslint:disable-next-line:max-line-length
      if (
        !objectPath.get(config, "config.aside.left.fixed") &&
        !objectPath.get(
          config,
          "config.menu.aside.desktop_and_mobile.submenu.accordion"
        )
      ) {
        this.isScrollable = 0;
        this.isDropdown = 1;
        this.dropdownTimeout = objectPath.get(
          config,
          "config.menu.aside.desktop_and_mobile.submenu.dropdown.hover_timeout"
        );
      }
    });
  }
  MakeRestrictedAsideItems(allItems: any[]): any[] {
    //all items
    var items = this.tokenStorage.getAsideItems();
    let testArr: any[] = [];
    if (items != null) {
      var alloweditems = JSON.parse(items);

      allItems.forEach((allItem) => {
        if (allItem.key == "dashboard" || allItem.section != undefined) {
          testArr.push(allItem);
        } else {
          //var obj = alloweditems.find(x=>x.key == allItem.key);
          alloweditems.forEach((item) => {
            if (allItem.key == item.key) {
              testArr.push(allItem);
            }
          });
        }
      });
    }
    return testArr;
  }
}
