import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChartsModule } from "ng2-charts";
import { BarChartComponent } from "./bar-chart/bar-chart.component";
import { DoughnutChartComponent } from "./doughnut-chart/doughnut-chart.component";

@NgModule({
  imports: [CommonModule, ChartsModule],
  exports: [BarChartComponent, DoughnutChartComponent],
  declarations: [BarChartComponent, DoughnutChartComponent],
})
export class WidgetChartsModule {}
