import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { HttpUtilsService } from '../utils/http-utils.service';
import { QueryParamsModel,QueryDrillsParamsModel } from '../models/query-models/query-params.model';
import { QueryResultsModel,QueryDrillsModel } from '../models/query-models/query-results.model';
import {BASE_API_URL} from "../../../../core/Globals/globals";
import { GetDrillsDataAPI,SelectListModel,OrdersProducts } from "../models/_getData.model";
import { OrderModel } from "../models/order.model";

const API_BASE_URL = BASE_API_URL;

@Injectable()
export class AttendencesService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new order to the server
	createOrder(order: OrderModel): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const url = API_BASE_URL+'/order_create';
		return this.http.post<any>(url, order, { headers: httpHeaders });
	}

	// // READ
	// getAllCustomers(): Observable<UserModel[]> {
	// 	const url = API_BASE_URL + '/user_list';
	// 	return this.http.get<UserModel[]>(url);
	// }

	getNotificationsTopBar() {
		const url = API_BASE_URL + '/dropdown_notification';
		return this.http.post<any>(url,{});
	}

	getDrillsForHeader(params: QueryDrillsParamsModel): Observable<QueryDrillsModel> {

		const url = API_BASE_URL + '/attendance_drills';
		return this.http.post<QueryDrillsModel>(url, params);
	}
	// getCustomerById(customerId: number): Observable<UserModel> {
	// 	return this.http.get<UserModel>(API_CUSTOMERS_URL + `/${customerId}`);
	// }

	findAttendence(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const url = API_BASE_URL + '/order_list';
		return this.http.post<QueryResultsModel>(url, queryParams, {
			headers: httpHeaders
		});
	}

	getOrderDetailsById(id:number):Observable<OrdersProducts>
	{
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			id: id
		};
		const url = API_BASE_URL + '/order_details';
		return this.http.post<OrdersProducts>(url, body, { headers: httpHeaders });
	}
	// // UPDATE => PUT: update the customer on the server
	// updateCustomer(customer: UserPOSTModel): Observable<any> {
	// 	const httpHeader = this.httpUtils.getHTTPHeaders();
	// 	const url = API_BASE_URL+'/user_edit';
	// 	return this.http.post(url,customer , { headers: httpHeader });
	// }

	// UPDATE Status
	updateStatusForOrder(idsForUpdation: number[], status: number): Observable<any> {
		
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			id: idsForUpdation,
			status: status
		};
		const url = API_BASE_URL + '/update_orderstatus';
		return this.http.post(url, body, { headers: httpHeaders });
	}

	// DELETE => delete the order from the server
	deleteOrder(orderId: number): Observable<any> {
		const url = API_BASE_URL +'/order_delete';
		return this.http.post<any>(url,{id:orderId});
	}

	deleteUsers(ids: number[] = []): Observable<any> {
		const url = API_BASE_URL + '/delete_allusers';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { id: ids };
		return this.http.post<QueryResultsModel>(url, body, { headers: httpHeaders });
	}
}