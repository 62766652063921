import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { TokenStorage } from "../../../../core/auth/token-storage.service";
import { debug } from "util";

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(private tokenService: TokenStorage) {}
  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // modify request
    request = request.clone({
      setHeaders: {
        Authorization: `${localStorage.getItem("accessToken")}`,
      },
    });
    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
          }
        },
        (error) => {
          //401 means Unauthorised
          if (error.status == "401") {
            this.tokenService.clear();
            location.reload();
          }
          // console.error(error.status);
          // console.error(error.message);
        }
      )
    );
  }
}
