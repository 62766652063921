import { Injectable, Input } from "@angular/core";
import { Observable, of } from "rxjs";
import { LayoutConfigService } from "../../core/services/layout-config.service";
import { LayoutConfig } from "../../config/layout";
@Injectable()
export class TokenStorage {
  @Input() model: any;
  constructor(private layoutConfig: LayoutConfigService) {
    this.layoutConfig.onLayoutConfigUpdated$.subscribe((config) => {
      this.model = config.config;
    });
  }
  /**
   * Get access token
   * @returns {Observable<string>}
   */
  public getAccessToken(): Observable<string> {
    const token: string = <string>localStorage.getItem("accessToken");
    return of(token);
  }

  /**
   * Get refresh token
   * @returns {Observable<string>}
   */
  public getRefreshToken(): Observable<string> {
    const token: string = <string>localStorage.getItem("refreshToken");
    return of(token);
  }
  /**
   * Get refresh token
   * @returns {Observable<string>}
   */
  public getAsideItems(): string {
    const token: string = <string>localStorage.getItem("asideItems");
    return token;
  }
  /**
   * Get refresh token
   * @returns {Observable<string>}
   */
  public getUserName(): string {
    const token: string = localStorage.getItem("user_name");
    return token;
  }
  /**
   * Get refresh token
   * @returns {Observable<string>}
   */
  public getUserEmail(): string {
    const token: string = localStorage.getItem("user_email");
    return token;
  }
  public getLayoutConfig(): string {
    const token: string = localStorage.getItem("layoutConfig");
    return token;
  }

  /**
   * Get user roles in JSON string
   * @returns {Observable<any>}
   */
  public getUserRoles(): Observable<any> {
    const roles: any = localStorage.getItem("userRoles");
    try {
      return of(JSON.parse(roles));
    } catch (e) {}
  }

  /**
   * Set access token
   * @returns {TokenStorage}
   */
  public setAccessToken(token: string): TokenStorage {
    localStorage.setItem("accessToken", token);

    return this;
  }

  /**
   * Set User Name
   * @returns {TokenStorage}
   */
  public setUserName(userName: string): TokenStorage {
    localStorage.setItem("user_name", userName);

    return this;
  }
  /**
   * Set User Email
   * @returns {TokenStorage}
   */
  public setUserEmail(userEmail: string): TokenStorage {
    localStorage.setItem("user_email", userEmail);

    return this;
  }

  /**
   * Set refresh token
   * @returns {TokenStorage}
   */
  public setRefreshToken(token: string): TokenStorage {
    localStorage.setItem("refreshToken", token);

    return this;
  }

  /**
   * Set user roles
   * @param roles
   * @returns {TokenStorage}
   */
  public setUserRoles(roles: any): any {
    if (roles != null) {
      localStorage.setItem("userRoles", JSON.stringify(roles));
    }

    return this;
  }
  public setLayout(layoutConfig: any): any {
    if (layoutConfig == "" || layoutConfig == undefined)
      localStorage.removeItem("layoutConfig");
    else {
      const obb = JSON.parse(layoutConfig);
      this.layoutConfig.setModel(new LayoutConfig(obb));
    }
    return this;
  }
  public setImageUrl(url: any): any {
    if (url == "" || url == undefined)
      localStorage.setItem(
        "user_image",
        "./assets/app/media/img/users/user4.jpg"
      );
    else {
      localStorage.setItem("user_image", url);
    }
    return this;
  }
  public getImageUrl(): string {
    const token: string = localStorage.getItem("user_image");
    return token;
  }

  //set aside permissions from server
  public setAsideRoles(asideItem: any): any {
    if (asideItem != null) {
      localStorage.setItem("asideItems", JSON.stringify(asideItem));
    }
    return this;
  }

  /**
   * Remove tokens
   */
  public clear() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("layoutConfig");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userRoles");
    localStorage.removeItem("asideItems");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_email");
    localStorage.removeItem("user_image");
  }
}
