//
// ===== File globals.ts
//
import { Injectable } from "@angular/core";
("use strict");

export const sep = "/";
export const version: string = "22.2.2";
//liveUrl
export const BASE_API_URL: string =
  "https://salesrep.neilmed.com/deploy/public";
export const BASE_IMG_URL: string =
  "https://salesrep.neilmed.com/deploy/public";

// export const BASE_API_URL: string = "http://3.136.159.247/deploy/public";
// export const BASE_IMG_URL: string = "http://3.136.159.247/deploy/public";

//  export const BASE_API_URL: string = "http://localhost/medmobile_local/public";
//  export const BASE_IMG_URL: string = "http://localhost/medmobile_local/public";

export const image300_40: string = "/images/users/thumb_theme_logo.png";

//for user Management  Module
@Injectable()
export class PermissionParams {
  add: string = "Create";
  update: string = "Edit";
  delete: string = "Delete";
  list: string = "List";
}

@Injectable()
export class ConditionalParam {
  YES: string = "Yes";
  NO: string = "No";
  SHIPPING: string = "Shipping";
  BILLING: string = "Billing";
  PERSONAL: string = "Personal";
  RAWSTATELIST: string = "rawStateList";
}
